import React from 'react';
import Report from 'components/ReportPowerBI/index';
import { models } from 'powerbi-client';
import $ from 'jquery';
import Container from '@material-ui/core/Container';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import MultipleSelect from "components/CustomDropdown/MultipleSelect";
import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

import { getToken } from "./../../config/adalConfig"

const reportUrl = 'https://powerbi-embed-api.azurewebsites.net/api/reports/c52af8ab-0468-4165-92af-dc39858d66ad';

// const SpaAuthService = {
//   "powerBiApiResourceId" : "https://analysis.windows.net/powerbi/api",
//   "authContext": "",
//   "userName": "",
//   "userIsAuthenticated": "",
//   "accessToken": "",
//   "uiUpdateCallback": ""
// }

// const adalConfig = {
//   tenant: "fbc493a8-0d24-4454-a815-f4ca58e8c09d",
//   clientId: "75ba7828-c418-48bf-b3b6-4ceaa4723d14",
//   endpoints: {
//     api: "75ba7828-c418-48bf-b3b6-4ceaa4723d14"
//   },
//   cacheLocation: "sessionStorage",
//   redirectUri: window.location.origin
// };

// SpaAuthService.authContext = new AuthenticationContext(adalConfig);      
// console.log(SpaAuthService.authContext);
// var isCallback = SpaAuthService.authContext.isCallback(window.location.hash);
// console.log(isCallback);
// SpaAuthService.authContext.handleWindowCallback();

// console.log(SpaAuthService.authContext);

// if (isCallback && !SpaAuthService.authContext.getLoginError()) {
//   var loginRequest = SpaAuthService.authContext.CONSTANTS.STORAGE.LOGIN_REQUEST;
//   console.log("3", loginRequest);
//   window.location.href = SpaAuthService.authContext._getItem(loginRequest);
// }

// console.log(SpaAuthService);
// var user = SpaAuthService.authContext.getCachedUser();
// console.log(user)
// if (user) {
//   SpaAuthService.authContext.acquireToken(SpaAuthService.powerBiApiResourceId,
//     function (error, token) {
//       if (error || !token) {
//         // TODO: Handle error obtaining access token
//         alert('ERROR:\n\n' + error);
//         return;
//       }
//       console.log("Access token acquired");
//       SpaAuthService.accessToken = token;
//       SpaAuthService.userIsAuthenticated = true;
//       SpaAuthService.userName = user.profile["name"];
//       if (SpaAuthService.uiUpdateCallback){
//         SpaAuthService.uiUpdateCallback();
//       }
//     });
// } 


// console.log(SpaAuthService);

    

    

export default class aaaFilterReport extends React.PureComponent {

  constructor(props) {
    super(props);
    this.classes = props.classes;
    this.state = {
      quantity: 1,
      embedConfig: undefined,
      solutionGroupNameList: [],
      tenantNameList: [],
      RegionList: [],
    }

    this.state.tenantNameList = ["Allan Guinot", "Andrew Ma", "Brad Sutton"]

    this.report = null;
    this.filterReport = this.filterReport.bind(this);
    this.handleReportLoad = this.handleReportLoad.bind(this);
    this.handleReportRender = this.handleReportRender.bind(this);
    this.setSolutionGroupNameList = this.setSolutionGroupNameList.bind(this);
    this.setTenantNameList = this.setTenantNameList.bind(this);
    this.setRegionList = this.setRegionList.bind(this);

    this.getConfig = this.getConfig.bind(this);

    this.getConfig().then((data) => {
      let c = {
        "accessToken": getToken(),
        "autoAuth": "true",
        "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=11c23906-6f01-4be3-b0ca-8436bfb44d74&groupId=fb0acb3f-bf38-424b-a338-dd4ef669816a&autoAuth=true&ctid=fbc493a8-0d24-4454-a815-f4ca58e8c09d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D",
        "id": "11c23906-6f01-4be3-b0ca-8436bfb44d74",
        "name": "Retail Analysis",
        "type": "report",
        "webUrl": "https://app.powerbi.com/reports/11c23906-6f01-4be3-b0ca-8436bfb44d74",
        "settings":{
            "filterPaneEnabled": false,
            "navContentPaneEnabled": false}
      }
      this.setState({embedConfig: c})
      // this.setState({embedConfig: data})

    });
    this.tenantNameList = props.tenantNameList;
    // console.log(this.tenantNameList);
  }

  setSolutionGroupNameList(data){
    this.state.solutionGroupNameList = data;
    this.filterReport();
  }

  setTenantNameList(data){
    this.state.tenantNameList = data;
    this.filterReport();
  }

  setRegionList(data){
    this.state.RegionList = data;
    this.filterReport();
  }

  async getConfig(){
    return await fetch(reportUrl)
    .then(async function (response) {
      if (response.ok) {
        return await response.json()
          .then(function (embedConfig) {
            // const customFilterPaneConfig = $.extend({}, embedConfig, {
            //   settings: {
            //     filterPaneEnabled: false,
            //     navContentPaneEnabled: true
            //   }
            // });
            return embedConfig;
          });
      }
      else {
        return response.json()
          .then(function (error) {
            throw new Error(error);
          });
      }
    })
  }

  filterReport(){
    const filterTypeTarget = {};
    filterTypeTarget.table = "District"; //$('#filtertable').val();
    filterTypeTarget.column = "District Manager"; //$('#filtercolumn').val();

    const operatorType = "basic";
    const operatorAndValues = {
      type: operatorType
    };
    if (operatorType === "basic") {
      if(this.tenantNameList.length == 0)
        operatorAndValues.operator = "All"; //OR "Out" //$('#filterbasicoperator').val();
      else
        operatorAndValues.operator = "In"; //OR "Out" //$('#filterbasicoperator').val();
      operatorAndValues.values = this.tenantNameList;
      // $('#name').val().split(',').map(function (index, element) {
      //   return index;
      // });
      console.log(operatorAndValues);
    }
    else if (operatorType === "advanced") {
      operatorAndValues.operator = "And"; //OR "Out" //$('#filterlogicaloperator').val();
      operatorAndValues.values = ["Chris Gray"];
      // $('.advanced-value')
      //   .map(function (index, element) {
      //     return {
      //       value: $(element).find('.advanced-value-input').val(),
      //       operator: $(element).find('.advanced-logical-condition').val()
      //     };
      //   });
    }

    const checkedTarget = "report" //$('#customfilterform input[name=filterTarget]:checked').val();
    const target = {
      type: checkedTarget
    };

    if (checkedTarget === "page") {
      target.name = $('#filtertargetpage').val();
    }
    else if (checkedTarget === "visual") {
      target.id = undefined; // Need way to populate visual ids
    }


    const data = {
      target: filterTypeTarget,
      operator: operatorAndValues,
      reportTarget: target
    };


    let filter = "";
    const values = Array.prototype.slice.call(data.operator.values);

    if (data.operator.type === "basic") {
      filter = new models.BasicFilter(data.target, data.operator.operator, values);
    }
    else if (data.operator.type === "advanced") {
      filter = new models.AdvancedFilter(data.target, data.operator.operator, values);
    }

  
    const filterTargetLevel = this.report;
    if (data.reportTarget.type === "page") {
      filterTargetLevel = this.customFilterPaneReport.page(data.reportTarget.name);
    }
    else if (data.reportTarget.type === "visual") {
      // Need to finalize how visuals report whichp age they are on in order to construct correct page object.
      filterTargetLevel = this.customFilterPaneReport.page('ReportSection1').visual(data.reportTarget.name);
    }

    const filterJson = filter.toJSON();
    filterTargetLevel.setFilters([filterJson]);
  }

  handleDataSelected = (data) => {
    // will be called when some chart or data element in your report clicked
  }

  handleReportLoad = (report) => {
    // will be called when report loads:
    // - scripts and data received from server, visuals are rendered on the browser
    // - flickering Power BI logo stops appearing but report is not fully ready to be consumed
    this.report = report; // get the object from callback and store it.(optional)
    // this.setAdminReport(this.report);
    this.filterReport();
  }

  handleReportRender = (report) => {
    // will be called when report renders:
    // - visuals finish rendering
    // - report is fully visible and ready for consumption
    this.report = report; // get the object from callback and store it.(optional)
  }

  handlePageChange = (data) => {
    // will be called when pages in your report changes
  }

  handleTileClicked = (data) => {
    console.log('Data from tile', data);
  }

  reloadReport = () => {
    this.report.reload();
  }

  render() {
    const reportStyle = {
      report: {
        height: '500px',
      }
    };
    const extraSettings = {
            filterPaneEnabled: false, //true
            navContentPaneEnabled: false, //true
            // ... more custom settings
    };
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          {/* <input type="text" id="name"></input>
          <button onClick={this.filterReport}>Click</button>
          <button onClick={this.reloadReport}>Reload</button>
          
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <MultipleSelect name="Solution Group Name" filterReport={this.setSolutionGroupNameList} data={this.state.tenantNameList}></MultipleSelect>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <MultipleSelect name="Tenant Name" filterReport={this.setTenantNameList} data={this.state.tenantNameList}></MultipleSelect>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <MultipleSelect name="Region" filterReport={this.setRegionList} data={this.state.tenantNameList}></MultipleSelect>
            </GridItem>
          </GridContainer>
          <br></br> */}
        {(this.state.embedConfig == undefined)? "":

        <>
          <Report
            id={this.state.embedConfig.id}
            embedUrl={this.state.embedConfig.embedUrl}
            accessToken={this.state.embedConfig.accessToken}
            tokenType="Aad"
            filterPaneEnabled={true}
            navContentPaneEnabled={false}
            onEmbedded={this.onEmbedded}
            
            embedType="report" // "dashboard"
            // tokenType="Embed" // "Aad"
            embedId={this.state.embedConfig.id} // report or dashboard Id goes here
            pageName="" // set as current page of the report
            extraSettings={extraSettings}
            permissions="All" // View
            style={reportStyle}
            onLoad={this.handleReportLoad}
            onRender={this.handleReportRender}
            onSelectData={this.handleDataSelected}
            onPageChange={this.handlePageChange}
            onTileClicked={this.handleTileClicked}
            embedConfig = {this.state.embedConfig}
          />
        </>}
        </GridItem>
      </GridContainer>
    );
  }
}
