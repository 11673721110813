import React from 'react';
import { models } from 'powerbi-client';
import Report from 'components/ReportPowerBI/index';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

export default class aaaFilterReport extends React.PureComponent {

  constructor(props) {
    super(props);
    this.classes = props.classes;
    this.tenantNameList = props.tenantNameList;
    this.solutionGroupNameList = props.solutionGroupNameList;
    this.reportUrl = 'https://powerbi-embed-api.azurewebsites.net/api/reports/c52af8ab-0468-4165-92af-dc39858d66ad';

    this.state = {
      embedConfig: undefined
    }

    this.report = null;
    this.filterReport = this.filterReport.bind(this);

    this.getConfig = this.getConfig.bind(this);
    this.getConfig().then((data) => {
      this.setState({embedConfig: data})
    });
  }

  async getConfig(){
    return await fetch(this.reportUrl)
    .then(async function (response) {
      if (response.ok) {
        return await response.json()
          .then(function (embedConfig) {
            return embedConfig;
          });
      }
      else {
        return response.json()
          .then(function (error) {
            throw new Error(error);
          });
      }
    })
  }

  filterReport(){
    const filterTypeTarget = {};
    filterTypeTarget.table = "District"; // Table name
    filterTypeTarget.column = "District Manager"; // Column name

    const operatorAndValues = {type: "basic"};
    if(this.tenantNameList.length == 0)
      operatorAndValues.operator = "All";
    else
      operatorAndValues.operator = "In";
    operatorAndValues.values = this.tenantNameList;
    
    const target = {type: "report"};

    const data = {
      target: filterTypeTarget,
      operator: operatorAndValues,
      reportTarget: target
    };

    const values = Array.prototype.slice.call(data.operator.values);

    const filter = new models.BasicFilter(data.target, data.operator.operator, values);

    const filterTargetLevel = this.report;

    const filterJson = filter.toJSON();
    filterTargetLevel.setFilters([filterJson]);
  }

  handleReportLoad = (report) => {
    this.report = report;
    this.filterReport();
  }

  reloadReport = () => {
    this.tenantNameList = [];
    this.solutionGroupNameList = [];
    this.filterReport();
  }

  render() {
    const reportStyle = {
      report: {
        height: '500px',
      }
    };
    const extraSettings = {
            filterPaneEnabled: false, 
            navContentPaneEnabled: false, 
    };
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        {(this.state.embedConfig == undefined)? "":

        <>
          <Report
            id={this.state.embedConfig.id}
            embedUrl={this.state.embedConfig.embedUrl}
            accessToken={this.state.embedConfig.accessToken}
            filterPaneEnabled={true}
            navContentPaneEnabled={false}
            onEmbedded={this.onEmbedded}
            
            embedType="report" 
            tokenType="Embed" // "Aad"
            embedId={this.state.embedConfig.id}
            pageName="" 
            extraSettings={extraSettings}
            permissions="All"
            style={reportStyle}
            onLoad={this.handleReportLoad}
            onRender={this.handleReportRender}
            onSelectData={this.handleDataSelected}
            onPageChange={this.handlePageChange}
            onTileClicked={this.handleTileClicked}
            embedConfig = {this.state.embedConfig}
          />
        </>}
        </GridItem>
      </GridContainer>
    );
  }
}
