import React from 'react';
import Report from 'components/ReportPowerBI/index';
import { models } from 'powerbi-client';
import $ from 'jquery';
import Container from '@material-ui/core/Container';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import MultipleSelect from "components/CustomDropdown/MultipleSelect";

const reportUrl = 'https://powerbi-embed-api.azurewebsites.net/api/reports/c52af8ab-0468-4165-92af-dc39858d66ad';

export default class aaaFilterReport extends React.PureComponent {

  constructor(props) {
    super(props);
    this.classes = props.classes;
    this.state = {
      quantity: 1,
      embedConfig: undefined,
      solutionGroupNameList: [],
      tenantNameList: [],
      RegionList: [],
    }

    this.state.tenantNameList = ["Allan Guinot", "Andrew Ma", "Brad Sutton"]

    this.report = null;
    this.filterReport = this.filterReport.bind(this);
    this.handleReportLoad = this.handleReportLoad.bind(this);
    this.handleReportRender = this.handleReportRender.bind(this);
    this.setSolutionGroupNameList = this.setSolutionGroupNameList.bind(this);
    this.setTenantNameList = this.setTenantNameList.bind(this);
    this.setRegionList = this.setRegionList.bind(this);

    this.getConfig = this.getConfig.bind(this);

    this.getConfig().then((data) => {
      // console.log(data)
      // data = {
      //   "accessToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IkJCOENlRlZxeWFHckdOdWVoSklpTDRkZmp6dyIsImtpZCI6IkJCOENlRlZxeWFHckdOdWVoSklpTDRkZmp6dyJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvZmJjNDkzYTgtMGQyNC00NDU0LWE4MTUtZjRjYTU4ZThjMDlkLyIsImlhdCI6MTU3NDM1MTU4OCwibmJmIjoxNTc0MzUxNTg4LCJleHAiOjE1NzQzNTU0ODgsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBU1FBMi84TkFBQUE5OU5mQ0NDWXhNamxWSXRHVU9QanVhNnlPbTRqdjBob1ptREs5cmJYWFAwPSIsImFtciI6WyJ3aWEiXSwiYXBwaWQiOiI4NzFjMDEwZi01ZTYxLTRmYjEtODNhYy05ODYxMGE3ZTkxMTAiLCJhcHBpZGFjciI6IjIiLCJmYW1pbHlfbmFtZSI6IlRpa2VrYXIiLCJnaXZlbl9uYW1lIjoiQ2hpbnRhbiIsImluX2NvcnAiOiJ0cnVlIiwiaXBhZGRyIjoiMTU5LjE0MC4yNTIuMTA5IiwibmFtZSI6IlRpa2VrYXIsQ2hpbnRhbiIsIm9pZCI6IjgxMDIwNTk3LTQ2MDgtNDVmMS04ODZhLTg4YzczODRkZjY5MyIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS02MDMxOTMyNS0xMTYwOTgyOTUxLTE2MDE3NzM5MDctNjM3NDE4IiwicHVpZCI6IjEwMDMyMDAwNDFENTZEQzYiLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzdWIiOiJ4ZHJRekJaM3luMjdwa0tNUmQ0T3J5R2ZZWVJXYTk0Z19pLVY1OFkzczhVIiwidGlkIjoiZmJjNDkzYTgtMGQyNC00NDU0LWE4MTUtZjRjYTU4ZThjMDlkIiwidW5pcXVlX25hbWUiOiJDVDA3MTY4MEBjZXJuZXIubmV0IiwidXBuIjoiQ1QwNzE2ODBAY2VybmVyLm5ldCIsInV0aSI6Inh1ckZRMXlDLWt1dlBVc0RFVXRlQUEiLCJ2ZXIiOiIxLjAiLCJ3aWRzIjpbIjliODk1ZDkyLTJjZDMtNDRjNy05ZDAyLWE2YWMyZDVlYTVjMyJdfQ.FNIvZjy_5cz_tfspIJj2Za2duGmAgtTGMjhb1trOEeeOWYCVD9TC7ShSmGbaVrDaKfcskGhZKK0yuwgI_rXLMcDzj3FefXDrhCWiCGAVKYtnmAZj_5DdEWJCGNu4vplQxDAR7Ll7EGtH5650T9EorJnhMt3Eym-gYv109RDSXGFaCUxPNYZK3Cuxw28iy-B25zDqLfQXa_Px88GPH7oI_FPfQLNXt_DOkLZnAkNDo8Vf9B3HVsaekCcD0ehMaToV67cYTStXX6W_IqO7RfEVmBomRG-9mGwjIpexSzWEHQpVIK2lwTKwgt6VVWDWjRWx226v5W_fyfjV5NUdAR7w2A",
      //   "autoAuth": "true",
      //   "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=4a50d45e-3c11-4f00-9298-4789025c8ed5&groupId=fb0acb3f-bf38-424b-a338-dd4ef669816a&autoAuth=true&ctid=fbc493a8-0d24-4454-a815-f4ca58e8c09d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D",
      //   "id": "14a50d45e-3c11-4f00-9298-4789025c8ed5",
      //   "name": "Retail Analysis",
      //   "tokenType": models.TokenType.Aad,
      //   "type": "report",
      //   "webUrl": "https://app.powerbi.com/reports/4a50d45e-3c11-4f00-9298-4789025c8ed5",
      //   "settings":{
      //       "filterPaneEnabled": false,
      //       "navContentPaneEnabled": false}
      // }
      this.setState({embedConfig: data})

    });
    this.tenantNameList = props.tenantNameList;
    // console.log(this.tenantNameList);
  }

  setSolutionGroupNameList(data){
    this.state.solutionGroupNameList = data;
    this.filterReport();
  }

  setTenantNameList(data){
    this.state.tenantNameList = data;
    this.filterReport();
  }

  setRegionList(data){
    this.state.RegionList = data;
    this.filterReport();
  }

  async getConfig(){
    return await fetch(reportUrl)
    .then(async function (response) {
      if (response.ok) {
        return await response.json()
          .then(function (embedConfig) {
            const customFilterPaneConfig = $.extend({}, embedConfig, {
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false
              }
            });
            return customFilterPaneConfig;
          });
      }
      else {
        return response.json()
          .then(function (error) {
            throw new Error(error);
          });
      }
    })
  }

  filterReport(){
    const filterTypeTarget = {};
    filterTypeTarget.table = "District"; //$('#filtertable').val();
    filterTypeTarget.column = "District Manager"; //$('#filtercolumn').val();

    const operatorType = "basic";
    const operatorAndValues = {
      type: operatorType
    };
    if (operatorType === "basic") {
      if(this.tenantNameList.length == 0)
        operatorAndValues.operator = "All"; //OR "Out" //$('#filterbasicoperator').val();
      else
        operatorAndValues.operator = "In"; //OR "Out" //$('#filterbasicoperator').val();
      operatorAndValues.values = this.tenantNameList;
      // $('#name').val().split(',').map(function (index, element) {
      //   return index;
      // });
      console.log(operatorAndValues);
    }
    else if (operatorType === "advanced") {
      operatorAndValues.operator = "And"; //OR "Out" //$('#filterlogicaloperator').val();
      operatorAndValues.values = ["Chris Gray"];
      // $('.advanced-value')
      //   .map(function (index, element) {
      //     return {
      //       value: $(element).find('.advanced-value-input').val(),
      //       operator: $(element).find('.advanced-logical-condition').val()
      //     };
      //   });
    }

    const checkedTarget = "report" //$('#customfilterform input[name=filterTarget]:checked').val();
    const target = {
      type: checkedTarget
    };

    if (checkedTarget === "page") {
      target.name = $('#filtertargetpage').val();
    }
    else if (checkedTarget === "visual") {
      target.id = undefined; // Need way to populate visual ids
    }


    const data = {
      target: filterTypeTarget,
      operator: operatorAndValues,
      reportTarget: target
    };


    let filter = "";
    const values = Array.prototype.slice.call(data.operator.values);

    if (data.operator.type === "basic") {
      filter = new models.BasicFilter(data.target, data.operator.operator, values);
    }
    else if (data.operator.type === "advanced") {
      filter = new models.AdvancedFilter(data.target, data.operator.operator, values);
    }

  
    const filterTargetLevel = this.report;
    if (data.reportTarget.type === "page") {
      filterTargetLevel = this.customFilterPaneReport.page(data.reportTarget.name);
    }
    else if (data.reportTarget.type === "visual") {
      // Need to finalize how visuals report whichp age they are on in order to construct correct page object.
      filterTargetLevel = this.customFilterPaneReport.page('ReportSection1').visual(data.reportTarget.name);
    }

    const filterJson = filter.toJSON();
    filterTargetLevel.setFilters([filterJson]);
  }

  handleDataSelected = (data) => {
    // will be called when some chart or data element in your report clicked
  }

  handleReportLoad = (report) => {
    // will be called when report loads:
    // - scripts and data received from server, visuals are rendered on the browser
    // - flickering Power BI logo stops appearing but report is not fully ready to be consumed
    this.report = report; // get the object from callback and store it.(optional)
    // this.setAdminReport(this.report);
    this.filterReport();
  }

  handleReportRender = (report) => {
    // will be called when report renders:
    // - visuals finish rendering
    // - report is fully visible and ready for consumption
    this.report = report; // get the object from callback and store it.(optional)
  }

  handlePageChange = (data) => {
    // will be called when pages in your report changes
  }

  handleTileClicked = (data) => {
    console.log('Data from tile', data);
  }

  reloadReport = () => {
    this.report.reload();
  }

  render() {
    const reportStyle = {
      report: {
        height: '500px',
      }
    };
    const extraSettings = {
            filterPaneEnabled: false, //true
            navContentPaneEnabled: false, //true
            // ... more custom settings
    };
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          {/* <input type="text" id="name"></input>
          <button onClick={this.filterReport}>Click</button>
          <button onClick={this.reloadReport}>Reload</button>
          
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <MultipleSelect name="Solution Group Name" filterReport={this.setSolutionGroupNameList} data={this.state.tenantNameList}></MultipleSelect>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <MultipleSelect name="Tenant Name" filterReport={this.setTenantNameList} data={this.state.tenantNameList}></MultipleSelect>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <MultipleSelect name="Region" filterReport={this.setRegionList} data={this.state.tenantNameList}></MultipleSelect>
            </GridItem>
          </GridContainer>
          <br></br> */}
        {(this.state.embedConfig == undefined)? "":

        <>
          <Report
            id={this.state.embedConfig.id}
            embedUrl={this.state.embedConfig.embedUrl}
            accessToken={this.state.embedConfig.accessToken}
            filterPaneEnabled={true}
            navContentPaneEnabled={false}
            onEmbedded={this.onEmbedded}
            
            embedType="report" // "dashboard"
            tokenType="Embed" // "Aad"
            embedId={this.state.embedConfig.id} // report or dashboard Id goes here
            pageName="" // set as current page of the report
            extraSettings={extraSettings}
            permissions="All" // View
            style={reportStyle}
            onLoad={this.handleReportLoad}
            onRender={this.handleReportRender}
            onSelectData={this.handleDataSelected}
            onPageChange={this.handlePageChange}
            onTileClicked={this.handleTileClicked}
            embedConfig = {this.state.embedConfig}
          />
        </>}
        </GridItem>
      </GridContainer>
    );
  }
}
