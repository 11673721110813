/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import "assets/css/material-dashboard-react.css?v=1.8.0";

import { authContext, getToken } from "./config/adalConfig";
import { runWithAdal } from "react-adal";

const hist = createBrowserHistory();
const DO_NOT_LOGIN = false;

/**
 * This function is used for Cerner OpenId sign-in.
 */
runWithAdal(
  authContext,
  () => {
    // require("./indexApp.js");
  },
  DO_NOT_LOGIN
);

let user = {
  "given_name": "Test User",
  "name": "test@cerner.com"
}

if(authContext['_user'] != null){
  user = {
    "given_name": authContext['_user']['profile']['name'],
    "name": authContext['_user']['userName']
  }
  console.log(user)
  // User.idToken = getToken();
}
/**
 * This part is used to show UI without Cerner OpenId sign-in.
 */
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/admin" component={() => <Admin user={user} />}/>
      <Redirect from="/" to="/admin/dashboard" user={user}/>
    </Switch>
  </Router>,
  document.getElementById("root")
);
