import React from 'react';
import Report from 'components/ReportPowerBI/index';
import { models } from 'powerbi-client';
import $ from 'jquery';
import Container from '@material-ui/core/Container';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import MultipleSelect from "components/CustomDropdown/MultipleSelect";

const reportUrl = 'https://powerbi-embed-api.azurewebsites.net/api/reports/c52af8ab-0468-4165-92af-dc39858d66ad';


export default class aaaFilterReport extends React.PureComponent {

  constructor(props) {
    super(props);
    this.classes = props.classes;
    this.state = {
      embedConfig: undefined,
      solutionGroupNameList: [],
      tenantNameList: [],
      RegionList: [],
    }

    this.state.tenantNameList = ["Allan Guinot", "Andrew Ma", "Brad Sutton"]

    this.report = null;
    this.filterReport = this.filterReport.bind(this);
    this.handleReportLoad = this.handleReportLoad.bind(this);
    this.handleReportRender = this.handleReportRender.bind(this);

    this.getConfig = this.getConfig.bind(this);
    this.getConfig().then((data) => {
      this.setState({embedConfig: data})
    });
    this.tenantNameList = props.tenantNameList;
    this.solutionGroupNameList = props.solutionGroupNameList;
  }

  async getConfig(){
    return await fetch(reportUrl)
    .then(async function (response) {
      if (response.ok) {
        return await response.json()
          .then(function (embedConfig) {
            const customFilterPaneConfig = $.extend({}, embedConfig, {
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: true
              }
            });
            return customFilterPaneConfig;
          });
      }
      else {
        return response.json()
          .then(function (error) {
            throw new Error(error);
          });
      }
    })
  }

  filterReport(){
    const filterTypeTarget = {};
    filterTypeTarget.table = "District"; //$('#filtertable').val();
    filterTypeTarget.column = "District Manager"; //$('#filtercolumn').val();

    const operatorType = "basic";
    const operatorAndValues = {
      type: operatorType
    };
    if (operatorType === "basic") {
      if(this.tenantNameList.length == 0)
        operatorAndValues.operator = "All"; //OR "Out" //$('#filterbasicoperator').val();
      else
        operatorAndValues.operator = "In"; //OR "Out" //$('#filterbasicoperator').val();
      operatorAndValues.values = this.tenantNameList;
      // $('#name').val().split(',').map(function (index, element) {
      //   return index;
      // });
    }
    else if (operatorType === "advanced") {
      operatorAndValues.operator = "And"; //OR "Out" //$('#filterlogicaloperator').val();
      operatorAndValues.values = ["Chris Gray"];
      // $('.advanced-value')
      //   .map(function (index, element) {
      //     return {
      //       value: $(element).find('.advanced-value-input').val(),
      //       operator: $(element).find('.advanced-logical-condition').val()
      //     };
      //   });
    }

    const checkedTarget = "report" //$('#customfilterform input[name=filterTarget]:checked').val();
    const target = {
      type: checkedTarget
    };

    if (checkedTarget === "page") {
      target.name = $('#filtertargetpage').val();
    }
    else if (checkedTarget === "visual") {
      target.id = undefined; // Need way to populate visual ids
    }


    const data = {
      target: filterTypeTarget,
      operator: operatorAndValues,
      reportTarget: target
    };


    let filter = "";
    const values = Array.prototype.slice.call(data.operator.values);

    if (data.operator.type === "basic") {
      filter = new models.BasicFilter(data.target, data.operator.operator, values);
    }
    else if (data.operator.type === "advanced") {
      filter = new models.AdvancedFilter(data.target, data.operator.operator, values);
    }

  
    const filterTargetLevel = this.report;
    if (data.reportTarget.type === "page") {
      filterTargetLevel = this.customFilterPaneReport.page(data.reportTarget.name);
    }
    else if (data.reportTarget.type === "visual") {
      // Need to finalize how visuals report whichp age they are on in order to construct correct page object.
      filterTargetLevel = this.customFilterPaneReport.page('ReportSection1').visual(data.reportTarget.name);
    }

    const filterJson = filter.toJSON();
    filterTargetLevel.setFilters([filterJson]);
  }

  handleDataSelected = (data) => {
    // will be called when some chart or data element in your report clicked
  }

  handleReportLoad = (report) => {
    // will be called when report loads:
    // - scripts and data received from server, visuals are rendered on the browser
    // - flickering Power BI logo stops appearing but report is not fully ready to be consumed
    this.report = report; // get the object from callback and store it.(optional)
    // this.setAdminReport(this.report);
    this.filterReport();
  }

  handleReportRender = (report) => {
    // will be called when report renders:
    // - visuals finish rendering
    // - report is fully visible and ready for consumption
    this.report = report; // get the object from callback and store it.(optional)
  }

  handlePageChange = (data) => {
    // will be called when pages in your report changes
  }

  handleTileClicked = (data) => {
    console.log('Data from tile', data);
  }

  reloadReport = () => {
    this.report.reload();
  }

  render() {
    const reportStyle = {
      report: {
        height: '500px',
      }
    };
    const extraSettings = {
            filterPaneEnabled: false, //true
            navContentPaneEnabled: false, //true
            // ... more custom settings
    };
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

        {(this.state.embedConfig == undefined)? "":
          <>
            <Report
              id={this.state.embedConfig.id}
              embedUrl={this.state.embedConfig.embedUrl}
              accessToken={this.state.embedConfig.accessToken}
              filterPaneEnabled={true}
              navContentPaneEnabled={false}
              onEmbedded={this.onEmbedded}
              
              embedType="report" // "dashboard"
              tokenType="Embed" // "Aad"
              embedId={this.state.embedConfig.id} // report or dashboard Id goes here
              pageName="" // set as current page of the report
              extraSettings={extraSettings}
              permissions="All" // View
              style={reportStyle}
              onLoad={this.handleReportLoad}
              onRender={this.handleReportRender}
              onSelectData={this.handleDataSelected}
              onPageChange={this.handlePageChange}
              onTileClicked={this.handleTileClicked}
              embedConfig = {this.state.embedConfig}
            />
          </>}
        </GridItem>
      </GridContainer>
    );
  }
}
