let apigClientFactory = require("aws-api-gateway-client").default;

class ReportFilterDAO {
    client = null;
    static tenants = [];
    static solutionGroupNames = [];
    static solutiontotenant = [];

    static solutionGroupNamesDict = {}
    static tenantsDict = {}
    static solutiongroup = {}
    
    constructor(accessKey, secretKey, sessionToken, invokeURL=process.env.REACT_APP_WEATHERMAN_API_INVOKEURL, region=process.env.REACT_APP_WEATHERMAN_API_REGION, retries=4) {
        this.client = apigClientFactory.newClient({
            invokeUrl: process.env.REACT_APP_WEATHERMAN_API_INVOKEURL, // REQUIRED
            accessKey: process.env.REACT_APP_WEATHERMAN_API_ACCESS_KEY, // REQUIRED
            secretKey: process.env.REACT_APP_WEATHERMAN_API_SECRET_KEY, // REQUIRED
            region: process.env.REACT_APP_WEATHERMAN_API_REGION
          });
    }

    async updateQueryParam(SearviceName, Region, Status, wm_next_page, fields, TenantId) {
        let queryParams = {};
    
        if(Status != null)
            queryParams['Status'] = Status;
        if(Region != null && Region !== "")
            queryParams['Region'] = Region;
        if(TenantId != null && TenantId !== "")
            queryParams['TenantId'] = TenantId;
        if(fields != null)
            queryParams['fields'] = fields.join();
        if(wm_next_page != null)
            queryParams['wm_next_page'] = "1";
        
        return queryParams;
    }
    
    async featchInstancesWithTenantId(SearviceName, Region, Status, wm_next_page, fields, TenantId) {
        console.log("Weatherman-API call.");

        let queryParams = await this.updateQueryParam(SearviceName, Region, Status, wm_next_page, fields, TenantId);
        
        var pathTemplate = "prod/v2/"+SearviceName;
        var body = {};
        var method = 'GET'
        var additionalParams = {
            headers: {
                'Content-Type': 'application/json'
            },
            queryParams: queryParams
        };
        return await this.client.invokeApi('123456789012', pathTemplate, method, additionalParams, body);
    }

    //--------------------------------------------------------------------------------------------------------------------------------
    static async getAllActiveTenantsV1(){
        console.log("Weatherman-API call. Get All Active Tenants.");
        let client = apigClientFactory.newClient({
            invokeUrl: process.env.REACT_APP_WEATHERMAN_API_INVOKEURL,
            accessKey: process.env.REACT_APP_WEATHERMAN_API_ACCESS_KEY,
            secretKey: process.env.REACT_APP_WEATHERMAN_API_SECRET_KEY,
            region: process.env.REACT_APP_WEATHERMAN_API_REGION
          });

        var pathTemplate = "prod/v1/tenants";
        var body = {};
        var method = 'GET'
        var additionalParams = {
            headers: {
                'Content-Type': 'application/json'
            },
            queryParams: {
                "Status": "Active",
                "fields": 'Name,Id',
                "CloudProvider": "aws"
            }
        };
        let response = await client.invokeApi('123456789012', pathTemplate, method, additionalParams, body);
        this.tenants = []
        this.tenantsDict = {}
        response.data.map(tenant => {
            this.tenants.push(tenant['Name']);
            this.tenantsDict[tenant['Id']] = tenant['Name'];
        })
    }

    static async getAllSolutionGroupNameV1(){
        console.log("Weatherman-API call. Get All SolutionGroupNames.");
        let client = apigClientFactory.newClient({
            invokeUrl: process.env.REACT_APP_WEATHERMAN_API_INVOKEURL,
            accessKey: process.env.REACT_APP_WEATHERMAN_API_ACCESS_KEY,
            secretKey: process.env.REACT_APP_WEATHERMAN_API_SECRET_KEY,
            region: process.env.REACT_APP_WEATHERMAN_API_REGION
          });

        var pathTemplate = "prod/v1/Solutiongroup";
        var body = {};
        var method = 'GET'
        var additionalParams = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let response = await client.invokeApi('123456789012', pathTemplate, method, additionalParams, body);
        response.data.map(solutionGroupName => {
            this.solutionGroupNames.push(solutionGroupName['solution_group_name']);
            this.solutionGroupNamesDict[solutionGroupName['Id']] = solutionGroupName['solution_group_name']
        })
        return this.solutionGroupNames;
    }

    static async getAllSolutiontotenantV1(){
        console.log("Weatherman-API call. Get All Solutiontotenant.");
        let client = apigClientFactory.newClient({
            invokeUrl: process.env.REACT_APP_WEATHERMAN_API_INVOKEURL,
            accessKey: process.env.REACT_APP_WEATHERMAN_API_ACCESS_KEY,
            secretKey: process.env.REACT_APP_WEATHERMAN_API_SECRET_KEY,
            region: process.env.REACT_APP_WEATHERMAN_API_REGION
          });

        var pathTemplate = "prod/v1/Solutiontotenant";
        var body = {};
        var method = 'GET'
        var additionalParams = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let response = await client.invokeApi('123456789012', pathTemplate, method, additionalParams, body);

        this.solutiongroup = {}
        response.data.map(data => {
            if(this.tenantsDict[data['TenantId']] != undefined){
                if(this.solutiongroup[this.solutionGroupNamesDict[data['SolutionGroupId']]] == undefined)
                    this.solutiongroup[this.solutionGroupNamesDict[data['SolutionGroupId']]] = [this.tenantsDict[data['TenantId']]]
                else
                    this.solutiongroup[this.solutionGroupNamesDict[data['SolutionGroupId']]].push(this.tenantsDict[data['TenantId']])
            }
            else
                this.solutiongroup[this.solutionGroupNamesDict[data['SolutionGroupId']]] = []
        });
    }

    static async loadData(){
        console.log("Weatherman-API call. LoadData().");
        await this.getAllActiveTenantsV1().then(async () => {
            await this.getAllSolutionGroupNameV1().then(async () => {
                await this.getAllSolutiontotenantV1()
            })
        })
    }

    static async getTenants(){
        return this.tenants;
    }

    static async getSolutionGroupNames(){
        return this.solutionGroupNames;
    }

    static async getSolutiontotenantV1(){
        return this.solutiongroup;
    }
}

export default ReportFilterDAO;