import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

export const adalConfig = {
  tenant: "fbc493a8-0d24-4454-a815-f4ca58e8c09d",
  clientId: "75ba7828-c418-48bf-b3b6-4ceaa4723d14",
  endpoints: {
    api: "75ba7828-c418-48bf-b3b6-4ceaa4723d14"
  },
  cacheLocation: "sessionStorage",
  redirectUri: "https://test2.test.cernerweatherman.com/"
};

// adalFetch(
//   fetch,
//   "https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName, 'Shanbh')"
// );

export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);
