import React from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import MultipleSelect from "components/CustomDropdown/MultipleSelect";
import ReportFilterDAO from "Services/ReportFilterDAO";


export default class aaaFilterReport extends React.PureComponent {

  constructor(props) {
    super(props);
    this.classes = props.classes;
    this.state = {
      quantity: 1,
      embedConfig: undefined,
      solutionGroupNameList: [],
      tenantNameList: [],
      tenantNameListAll: [],
      solutionGroupNamesDict: {}
    }

    this.report = null;
    // this.filterReport = this.filterReport.bind(this);
    this.setSolutionGroupNameList = this.setSolutionGroupNameList.bind(this);
    this.setTenantNameList = this.setTenantNameList.bind(this);
  }

  componentDidMount() {
    ReportFilterDAO.loadData().then(async (data) => {
      await ReportFilterDAO.getTenants().then((data) => {
        data.push("Allan Guinot");
        data.push("Andrew Ma");
        data.push("Brad Sutton");
        data.sort();
        this.setState({tenantNameList: data});
        this.setState({tenantNameListAll: data});
      })
      await ReportFilterDAO.getSolutionGroupNames().then((data) => {
        data.sort();
        this.setState({solutionGroupNameList: data});
      })
      await ReportFilterDAO.getSolutiontotenantV1().then((data) => {
        this.setState({solutionGroupNamesDict: data});
      })
    });
  }

  setSolutionGroupNameList(data){
    let tenantlist = []
    
    if(data.length !== 0){
      for (const solutionGroupName of data){
        if(this.state.solutionGroupNamesDict[solutionGroupName] !== undefined){
          tenantlist = tenantlist.concat(this.state.solutionGroupNamesDict[solutionGroupName]);
        }
      }
    }
    else{
      tenantlist = this.state.tenantNameListAll;
    }
    this.setState({tenantNameList :tenantlist})
    // this.filterReport();
    this.props.setSolutionGroupNameList(data);
  }

  setTenantNameList(data){
    this.props.setTenantNameList(data);
  }

  reloadReport = () => {
    this.props.adminReport.reload();
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>          
          <GridContainer alignItems="center" justify="center">
            <GridItem xs={12} sm={3} md={3}>
              <MultipleSelect name="Solution Group Name" filterReport={this.setSolutionGroupNameList} data={this.state.solutionGroupNameList}></MultipleSelect>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <MultipleSelect name="Tenant Name" filterReport={this.setTenantNameList} data={this.state.tenantNameList}></MultipleSelect>
            </GridItem>
            {/* <GridItem xs={12} sm={2} md={2}>
              <Button type="button" color="info" onClick={this.reloadReport}>Reload</Button>
            </GridItem> */}
            {/* <GridItem xs={12} sm={4} md={4}>
              <MultipleSelect name="Region" filterReport={this.setRegionList} data={this.state.tenantNameList}></MultipleSelect>
            </GridItem> */}
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}
