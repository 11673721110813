/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";

// AWS View
import aaaFilterReport from "views/AWS/aaaFilterReport"
import aaaFilterReport1 from "views/AWS/aaaFilterReport1"
import aaaFilterReport2 from "views/AWS/aaaFilterReport2"
import aaaFilterReport3 from "views/AWS/aaaFilterReport3"
import ACMCertificateView from "views/AWS/ACMCertificateView";
import AMIView from "views/AWS/AMIView";
import APIGatewayView from "views/AWS/APIGatewayView";
import AutoscalingGroupView from "views/AWS/AutoscalingGroupView";
import KMSView from "views/AWS/AWSKMSView";
import CloudTrailView from "views/AWS/CloudTrailView";
import CloudWatchEventRuleView from "views/AWS/CloudWatchEventRuleView";
import CloudWatchLogGroupView from "views/AWS/CloudWatchLogGroupView";
import DynamodbTableView from "views/AWS/DynamodbTableView";
import EBSVolumeView from "views/AWS/EBSVolumeView";
import EC2View from "views/AWS/EC2View"; 
import ElasticLoadBalancerView from "views/AWS/ElasticLoadBalancerView";
import EMRClusterView from "views/AWS/EMRClusterView";
import GuarddutyDetectorView from "views/AWS/GuarddutyDetectorView";
import IAMRoleView from "views/AWS/IAMRoleView";
import IAMPoliciesView from "views/AWS/IAMPoliciesView";
import LambdaFuctionsView from "views/AWS/LambdaFuctionsView";
import KinesisStreamView from "views/AWS/KinesisStreamView";
import Route53RecordSetView from "views/AWS/Route53RecordSetView";
import RDSView from "views/AWS/RDSSnapshotView";
import S3BucketView from "views/AWS/S3BucketView";
import VPCView from "views/AWS/VPCView";
import VPCSubnetView from "views/AWS/VPCSubnetView";
import VPNConnectionView from "views/AWS/VPNConnectionView";
// AWS Icon/SVG
import {ReactComponent as ACMCertificateIcon} from "assets/icons/AWS-ACM_Certificate.svg";
import {ReactComponent as AMIIcon} from "assets/icons/AWS-AMI.svg";
import {ReactComponent as APIGatewayIcon} from "assets/icons/AWS-API_Gateway.svg";
import {ReactComponent as AutoscalingGroupIcon} from "assets/icons/AWS-Auto_Scaling.svg";
import {ReactComponent as KMSIcon} from "assets/icons/AWS-KMS.svg";
import {ReactComponent as CloudTrailIcon} from "assets/icons/AWS-CloudTrail.svg";
import {ReactComponent as CloudWatchEventRuleIcon} from "assets/icons/AWS-CloudWatch_Event_Rule.svg";
import {ReactComponent as CloudWatchLogGroupIcon} from "assets/icons/AWS-CloudWatch_Log_Group.svg";
import {ReactComponent as DynamodbTableIcon} from "assets/icons/AWS-DynamoDB_Table.svg";
import {ReactComponent as EBSVolumeIcon} from "assets/icons/AWS-EBS_Volume.svg";
import {ReactComponent as ElasticLoadBalancerIcon} from "assets/icons/AWS-Elastic_Load_Balancer.svg";
import {ReactComponent as EMRClusterIcon} from "assets/icons/AWS-EMR_Cluster.svg";
import {ReactComponent as EC2Icon} from "assets/icons/AWS-EC2.svg";
import {ReactComponent as GuarddutyDetectorIcon} from "assets/icons/AWS-GuardDuty.svg";
import {ReactComponent as IAMRoleIcon} from "assets/icons/AWS-IAM_Role.svg";
import {ReactComponent as IAMPoliciesIcon} from "assets/icons/AWS-IAM_Policy.svg";
import {ReactComponent as LambdaFuctionsIcon} from "assets/icons/AWS-Lambda.svg";
import {ReactComponent as KinesisStreamIcon} from "assets/icons/AWS-Kinesis_Streams.svg";
import {ReactComponent as Route53RecordSetIcon} from "assets/icons/AWS-Route53.svg";
import {ReactComponent as RDSIcon} from "assets/icons/AWS-RDS.svg";
import {ReactComponent as S3BucketIcon} from "assets/icons/AWS-S3.svg";
import {ReactComponent as VPCIcon} from "assets/icons/AWS-VPC.svg";
import {ReactComponent as VPCSubnetIcon} from "assets/icons/AWS-VPC_Subnet.svg";
import {ReactComponent as VPNConnectionIcon} from "assets/icons/AWS-VPN_Connection.svg";

const dashboardRoutes = [
  {path: "/dashboard", name: "Dashboard", rtlName: "لوحة القيادة", icon: Dashboard, component: DashboardPage, layout: "/admin"},
  {path: "/TestingFilter", name: "Testing Filter", rtlName: " ", icon: Dashboard, component: aaaFilterReport, layout: "/admin"},
  {path: "/TestingFilter1", name: "Testing Filter1", rtlName: " ", icon: Dashboard, component: aaaFilterReport1, layout: "/admin"},
  {path: "/TestingFilter2", name: "Testing Filter2", rtlName: " ", icon: Dashboard, component: aaaFilterReport2, layout: "/admin"},
  {path: "/TestingFilter3", name: "Testing Filter3", rtlName: " ", icon: Dashboard, component: aaaFilterReport3, layout: "/admin"},
  {path: "/ACMCertificate", name: "ACMCertificate", rtlName: " ", icon: ACMCertificateIcon, component: ACMCertificateView, layout: "/admin"},
  {path: "/AMI", name: "AMI", rtlName: " ", icon: AMIIcon, component: AMIView, layout: "/admin"},
  {path: "/APIGateway", name: "APIGateway", rtlName: " ", icon: APIGatewayIcon, component: APIGatewayView, layout: "/admin"},
  {path: "/AutoscalingGroup", name: "AutoscalingGroup", rtlName: " ", icon: AutoscalingGroupIcon, component: AutoscalingGroupView, layout: "/admin"},
  {path: "/KMS", name: "KMS", rtlName: " ", icon: KMSIcon, component: KMSView, layout: "/admin"},
  {path: "/CloudTrail", name: "CloudTrail", rtlName: " ", icon: CloudTrailIcon, component: CloudTrailView, layout: "/admin"},
  {path: "/CloudWatchEventRule", name: "CloudWatchEventRule", rtlName: " ", icon: CloudWatchEventRuleIcon, component: CloudWatchEventRuleView, layout: "/admin"},
  {path: "/CloudWatchLogGroup", name: "CloudWatchLogGroup", rtlName: " ", icon: CloudWatchLogGroupIcon, component: CloudWatchLogGroupView, layout: "/admin"},
  {path: "/DynamodbTable", name: "DynamodbTable", rtlName: " ", icon: DynamodbTableIcon, component: DynamodbTableView, layout: "/admin"},
  {path: "/EBS", name: "EBS", rtlName: " ", icon: EBSVolumeIcon, component: EBSVolumeView, layout: "/admin"},
  {path: "/EC2", name: "EC2", rtlName: " ", icon: EC2Icon, component: EC2View, layout: "/admin"},
  {path: "/ElasticLoadBalancer", name: "ElasticLoadBalancer", rtlName: " ", icon: ElasticLoadBalancerIcon, component: ElasticLoadBalancerView, layout: "/admin"},
  {path: "/EMRCluster", name: "EMRCluster", rtlName: " ", icon: EMRClusterIcon, component: EMRClusterView, layout: "/admin"},
  {path: "/GuarddutyDetector", name: "GuarddutyDetector", rtlName: " ", icon: GuarddutyDetectorIcon, component: GuarddutyDetectorView, layout: "/admin"},
  {path: "/IAMRole", name: "IAMRole", rtlName: " ", icon: IAMRoleIcon, component: IAMRoleView, layout: "/admin"},
  {path: "/IAMPolicies", name: "IAMPolicies", rtlName: " ", icon: IAMPoliciesIcon, component: IAMPoliciesView, layout: "/admin"},
  {path: "/LambdaFuctions", name: "LambdaFuctions", rtlName: " ", icon: LambdaFuctionsIcon, component: LambdaFuctionsView, layout: "/admin"},
  {path: "/KinesisStream", name: "KinesisStream", rtlName: " ", icon: KinesisStreamIcon, component: KinesisStreamView, layout: "/admin"},
  {path: "/Route53RecordSet", name: "Route53RecordSet", rtlName: " ", icon: Route53RecordSetIcon, component: Route53RecordSetView, layout: "/admin"},
  {path: "/RDS", name: "RDS", rtlName: " ", icon: RDSIcon, component: RDSView, layout: "/admin"},
  {path: "/S3Bucket", name: "S3Bucket", rtlName: " ", icon: S3BucketIcon, component: S3BucketView, layout: "/admin"},
  {path: "/VPC", name: "VPC", rtlName: " ", icon: VPCIcon, component: VPCView, layout: "/admin"},
  {path: "/VPCSubnet", name: "VPCSubnet", rtlName: " ", icon: VPCSubnetIcon, component: VPCSubnetView, layout: "/admin"},
  {path: "/VPNConnection", name: "VPNConnection", rtlName: " ", icon: VPNConnectionIcon, component: VPNConnectionView, layout: "/admin"}
];

export default dashboardRoutes;
