import React from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export default class CloudWatchEventRuleView extends React.Component {

  constructor(props) {
    super(props);
    this.classes = props.classes;
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <iframe width="100%" height="900" title="view" src="https://app.powerbi.com/reportEmbed?reportId=ad1f239f-194e-4715-82fa-4fcb6f826560&groupId=fb0acb3f-bf38-424b-a338-dd4ef669816a&autoAuth=true&ctid=fbc493a8-0d24-4454-a815-f4ca58e8c09d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D" frameborder="0" allowFullScreen="true"></iframe>
        </GridItem>
      </GridContainer>
    );
  }
}
